<template>
  <div class="">
    <div class="flex justify-between items-center pb-4 i-border-b-1 border-secondary-five border-solid pt-4 w-full">
          <span class="font-poppins fw-600 text-primary-one w-full">
            {{ survey.title }}
          </span>
      <div class="flex gap-6">
        <button @click="sendMessage()" class="focus:outline-none rounded fs-14 font-poppins text-secondary-one bg-primary-three h-10 i-pl-30 i-pr-30 i-border-1 border-secondary-one">Save</button>
      </div>
    </div>
    <div class="send-info-box overflow-y-auto">
      <div class="pt-6 pb-5 flex justify-between i-border-b-1 border-solid border-secondary-five">
        <div class="flex items-center">
          <h2 class="fs-14 font-poppins text-primary-one">To:</h2>
          <vue-tags-input
              v-model="email"
              :tags="emails"
              placeholder="Type email here"
              class="i-ml-35 vue-tags-width"
              @tags-changed="newEmails => emails = newEmails"
              @before-adding-tag="beforeAddEmail"
          />
        </div>
      </div>
      <div class="i-border-b-1 border-solid border-secondary-five py-5 flex">
        <div class="flex items-center">
          <h2 class="fs-14 font-poppins text-primary-one">From:</h2>
          <h2 class="i-ml-30 fs-14 font-poppins text-primary-one">{{ getAuthUser ? getAuthUser.email : 'N/A' }}</h2>
        </div>
      </div>
      <div class="py-5 i-border-b-1 border-solid border-secondary-five flex">
        <div class="flex items-center">
          <h2 class="fs-14 font-poppins text-primary-one">Subject:</h2>
          <input v-model="sendingInfo.subject" class="ml-4 fs-14 font-poppins text-primary-one bg-transparent placeholder-secondary-two" placeholder="Type Subject here" />
        </div>
      </div>
      <!--        <div class="relative rounded mt-5 bg-gray-100 bg-cover bg-center bg-no-repeat">-->
      <!--          <img :src="`${(previewURL) ? previewURL : assessItem?.definition?.file?.file_path}`" alt="" class="block w-full">-->
      <!--          <div @click="$refs.inputFile.click()" class="bg-white absolute top-4 right-4 w-8 h-8 rounded-full flex justify-center items-center shadow-one cursor-pointer ">-->
      <!--            <EditIcon class="w-4 h-4" />-->
      <!--          </div>-->
      <!--        </div>-->
      <div class="mt-5">
        <textarea v-model="sendingInfo.message" class="fs-16 font-poppins text-primary-one bg-transparent placeholder-secondary-two w-full bld-content-area send-textarea" placeholder="Write your message here"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input';
import LandingLeft from "../../../components/survey/LandingLeft";
import {mapGetters} from "vuex";
export default {
  name: "Send",
  components: {LandingLeft, VueTagsInput},
  created() {
    // const self = this;
    // self.$Progress.start();
    // self.$store.dispatch("survey/getSingleSurvey", self.$route.params.survey_id).then(response => {
    //   self.$Progress.finish();
    // });
  },
  data() {
    return {
      email: '',
      emails: [],
      sendingInfo: {},
    }
  },
  computed: {
    ...mapGetters({
      survey: "survey/survey",
    }),
    getAuthUser() {
      let user = JSON.parse(localStorage.getItem('_user'));
      if(user) {
        return user;
      }
      return '';
    },
  },
  methods: {
    gotoSurvey() {
      this.$router.push({ name: 'survey.landing' });
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    beforeAddEmail(obj) {
      if(this.validateEmail(obj.tag.text)) {
        obj.addTag();
      }
    },
    sendMessage() {
      const self = this;
      self.$Progress.start();
      self.sendingInfo.assignee = self.emails;
      self.sendingInfo.survey_id = self.$route.params.survey_id;
      self.$store.dispatch("survey/sendMessage", self.sendingInfo).then(response => {
        self.$Progress.finish();
        if(response && response.data && response.data.status) {
          this.gotoSurvey();
        }
      });
    },
    copyPreviewLink() {
      let url = window.location.origin + `/survey/page/${this.$route.params.survey_id}`;
      navigator.clipboard.writeText(url);
      alert('Copied url');
    }
  }
}
</script>

<style scoped>
.assess-major-part {
  padding: 30px;
}
.assess-right-part {
  margin-left: 10px;
  width: 1496px;
}
.grey{
  color: #787878;
}
.e7 {
  color: #e7e7e7;
}
.a5 {
  color: #A5A5A5;
}
.breadcrumb span {
  margin-right: 10px;
}
.message-box {
  background: #232326;
  box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  margin-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  min-height: 895px;
}

.message-heading {
  padding: 18px 0;
  border-bottom: 1px solid #414141;
}

.assessment-blue-text {
  color: #2A93C0;
}

.assessment-blue-text:hover {
  color: #29799b;
}
.message-to {
  border-bottom: 1px solid #414141;
  padding: 12px 0;
}
.assess-select-box {
  background: #232326;
  border: none;
  outline: none;
  cursor: pointer;
}
.assess-select-box:focus {
  border: none;
  outline: none;
}
.assess-right-select {
  margin-right: -4px;
}
.message-from {
  border-bottom: 1px solid #414141;
  padding: 20px 0;
}
.input-text::placeholder {
  color: #787878;
}
.message-subject {
  border-bottom: 1px solid #414141;
  padding: 20px 0;
}
.message-text-box {
  padding: 22px 0;
}
.message-img {
  width: 1375px;
  height: 260px;
}
.message-textarea {
  width: 100%;
  min-height: 335px;
}
.message-textarea::placeholder {
  color: #787878;
}
.message-info-box {
  height: 835px;
  overflow-y: auto;
}
.message-buttons {
  bottom: 20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.message-button {
  background: #2A2A2E;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.35);
  border-radius: 2px;
  padding: 10px 15px;
}
.message-button:hover {
  background: #333336;
}
.message-img-delete-box {
  right: 20px;
  top: 20px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #232326;
}
.img-delete {
  margin-top: 5px;
  margin-left: 6px;
}
</style>
