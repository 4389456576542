<template>
  <div class="font-poppins">
    <workspace-left-menu />

    <router-link :to="{ name: 'survey.responses' }">
      <button
          class="ws-folder-button focus:outline-none hover:bg-secondary-five text-secondary-three fs-14 font-poppins fw-600 bg-primary-four shadow-one mt-5 w-full rounded flex items-center justify-center">
        See Responses
      </button>
    </router-link>

    <button
        class="ws-folder-button focus:outline-none hover:bg-secondary-five text-primary-one fs-14 font-poppins fw-600 bg-primary-three border-primary-four i-border-1 shadow-one w-full mt-9 rounded flex items-center justify-center">
      Programmatic Assessment
    </button>

    <div class="info-div">
      <div v-for="(type, index) in dataListTypes" :key="index"
           @click="setActive(index)">
        <div class="flex justify-between items-center cursor-pointer mt-8 opacity-60 text-primary-one assess-titles font-poppins fs-14 fw-600" :class="{'opacity-100': type.status}">
          <span>{{ type.title }}</span>
          <span>{{ getDataCount(index) }}</span>
        </div>
      </div>
    </div>

    <div class="flex flex-col social-div">
      <span class="share-link fw-500">Share To Team</span>
      <span class="share-link mt-5 fw-500">Share on Instrat Community</span>
      <span class="share-link mt-5 fw-500">invite collaborators</span>
    </div>
    <div class="flex social i-mt-25">
      <img src="/images/icons/assessment/facebook.svg" alt="">
      <img src="/images/icons/assessment/google.svg" alt="">
      <img src="/images/icons/assessment/linkedin.svg" alt="">
      <img src="/images/icons/assessment/twitter.svg" alt="">
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
  name: "LandingLeft",
  props: ['parent'],
  data() {
    return {
      dataListTypes: [
        {title: 'My Survey', status: true},
        {title: 'Invited', status: false},
        // {title: 'Deleted', status: false}
      ],
    }
  },
  computed: {
    ...mapGetters({
      surveys: "survey/surveys"
    }),
  },
  methods: {
    createSurvey() {
      this.$Progress.start();
      this.$store.dispatch("survey/storeSurvey")
          .then(response => {
            if(response && response.data && response.data.status) {
              this.$router.push({name: 'survey.edit', params: {survey_id: response.data.data.id}});
            }
            this.$Progress.finish();
          });
    },
    getDataCount(index) {
      let count = 0
      if (index == 0) {
        if (this.surveys.own) {
          count = this.surveys.own.filter(el => el.deleted_at == null).length;
        }
      }
      else if (index == 1) {
        if (this.surveys.invited) {
          count = this.surveys.invited.length;
        }
      }
      else if (index == 2) {
        if (this.surveys.own) {
          count = this.surveys.own.filter(el => el.deleted_at != null).length;
        }
      }
      return count
    },
    setActive(index) {
      this.dataListTypes[index].status = true
      this.dataListTypes.forEach((el, i) => {
        if (index != i) {
          el.status = false
        }
      })
      this.$store.dispatch('survey/getActiveListIndex', index)
    }
  }
}
</script>

<style scoped>
.assess-titles:hover {
  opacity: 100;
}

.info-div {
  height: 340px;
  border-bottom: 1px solid #8D8D8D;
  margin-bottom: 30px;
}

.social-div span, .share-link {
  font-size: 14px;
  line-height: 1rem;
  color: #787878;
}

.info-div div {
  margin-bottom: 30px;
}

.social img {
  margin-right: 30px;
  cursor: pointer;
}
.active-color span {
  color: #C4C4C4;
}
</style>
